<div class="flex flex-col h-full max-w-5xl mx-auto bg-gray-50">
  <app-chat-header
    [activeChats]="activeChats"
    [currentChat]="currentChat"
    [assistants]="assistants"
    (addChat)="addChat($event)"
    (removeChat)="removeChat($event)"
    (setCurrentChat)="setCurrentChat($event)"
  ></app-chat-header>

  <div class="flex-1 overflow-y-auto p-4 space-y-4">
    <app-chat-message
      *ngFor="let message of getCurrentChatData()?.messages"
      [message]="message"
      [currentAssistantId]="currentChat"
    ></app-chat-message>
  </div>

  <form class="p-4 bg-white border-t">
    <div class="flex gap-2">
      <input
        type="text"
        [formControl]="messageInput"
        placeholder="Type your message..."
        class="flex-1 px-4 py-2 rounded-full border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        (keydown.enter)="$event.preventDefault(); sendMessage()"
      />
      <button
        type="button"
        [class]="
          'p-2 rounded-full text-white transition-colors ' +
          getAssistantColors(currentChat).bg +
          ' ' +
          getAssistantColors(currentChat).hover
        "
        (click)="sendMessage()"
      >
        <lucide-icon [img]="SendIcon" [size]="20"></lucide-icon>
      </button>
    </div>
  </form>
</div>
