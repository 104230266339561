<div class="bg-white border-b px-6 py-4">
  <div class="flex items-center gap-4 mb-4">
    <app-back-button destination="/"></app-back-button>
    <h1 class="text-2xl font-semibold text-gray-800">AI Assistants</h1>
    <div class="relative group">
      <button
        class="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
      >
        + New Chat
      </button>
      <div
        class="absolute hidden group-hover:block w-48 bg-white shadow-lg rounded-lg p-2 z-10"
      >
        <button
          *ngFor="let assistant of availableAssistants"
          (click)="addChat.emit(assistant.id)"
          class="w-full text-left px-3 py-2 rounded hover:bg-gray-100 text-sm"
        >
          {{ assistant.name }}
        </button>
      </div>
    </div>
  </div>

  <div class="flex gap-2 overflow-x-auto pb-2">
    <button
      *ngFor="let chat of activeChats"
      (click)="setCurrentChat.emit(chat.assistantId)"
      [class]="
        'flex items-center gap-2 px-4 py-2 rounded-full text-sm ' +
        (currentChat === chat.assistantId
          ? getAssistantColors(chat.assistantId).bg + ' text-white'
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200')
      "
    >
      <span>{{ getAssistantName(chat.assistantId) }}</span>
      <lucide-icon
        *ngIf="activeChats.length > 1"
        [img]="XIcon"
        [size]="14"
        (click)="$event.stopPropagation(); removeChat.emit(chat.assistantId)"
        class="hover:bg-black/10 rounded"
      ></lucide-icon>
    </button>
  </div>
</div>
