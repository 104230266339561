import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BackButtonComponent } from '../back/back.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, BackButtonComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  email: string = '';
  infoMessage = '';

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async submit() {
    this.infoMessage = '';
    const response = await this.authService.sendMagicLink(this.email)
    if (response.magicLink) {
      console.log(response.magicLink);
      this.router.navigate(['login', 'sent'], { queryParams: { email: this.email } });
      return;
    }
    this.infoMessage = response.message;
  }
}
