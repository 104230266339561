import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  stagger,
} from '@angular/animations';

export const ANIMATION_TIMING = {
  DURATION: '400ms',
  EASE: 'ease-out',
  STAGGER_DELAY: 500
} as const;

export const insightAnimations = {
  expandSection: trigger('expandSection', [
    state('void', style({
      height: '0',
      opacity: 0,
      visibility: 'hidden'  // Replace overflow with visibility
    })),
    state('false', style({
      height: '0',
      opacity: 0,
      visibility: 'hidden'
    })),
    state('true', style({
      height: '*',
      opacity: 1,
      visibility: 'visible'
    })),
    transition('false => true', [
      style({ height: '0', opacity: 0, visibility: 'hidden' }),
      animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`)
    ]),
    transition('true => false', [
      style({ height: '*', opacity: 1, visibility: 'visible' }),
      animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`)
    ])
  ]),

  staggerItems: trigger('staggerItems', [
    transition('* => *', [
      query(':enter', [
        style({
          opacity: 0,
          transform: 'translateZ(-200px)'
        }),
        stagger(ANIMATION_TIMING.STAGGER_DELAY, [
          animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`,
            style({
              opacity: 1,
              transform: 'translateZ(0)'
            })
          )
        ])
      ], { optional: true }),

      query(':leave', [
        stagger(-ANIMATION_TIMING.STAGGER_DELAY, [
          animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`,
            style({
              opacity: 0,
              transform: 'translateY(-10px)'
            })
          )
        ])
      ], { optional: true })
    ])
  ])
};

// Optional additional animations that can be added as needed
export const additionalAnimations = {
  scale: trigger('scale', [
    transition(':enter', [
      style({ transform: 'scale(0.95)', opacity: 0 }),
      animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`,
        style({
          transform: 'scale(1)',
          opacity: 1
        })
      )
    ])
  ]),

  slideFromSide: trigger('slideFromSide', [
    transition(':enter', [
      style({ transform: 'translateX(-20px)', opacity: 0 }),
      animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`,
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      )
    ])
  ]),

  bounce: trigger('bounce', [
    transition(':enter', [
      style({ transform: 'translateY(-10px)', opacity: 0 }),
      animate(`${ANIMATION_TIMING.DURATION} ${ANIMATION_TIMING.EASE}`,
        style({ transform: 'translateY(3px)', opacity: 1 })
      ),
      animate('100ms ease-out', style({ transform: 'translateY(0)' }))
    ])
  ])
} as const;

// Helper type for strongly typed animation references
export type InsightAnimationKey = keyof typeof insightAnimations;
