<div class="border-l-4" [class]="borderColorClass()">
  <div class="insight pl-6" [class]="borderColorClass()" (click)="handleClick()">
    <div class="font-light text-grey-800 text-xs flex items-center gap-[2px]">
      <span>{{ type() }}</span>
      <lucide-angular class="w-3" [name]="infoIcon"></lucide-angular>
    </div>
    <h4 class="font-medium">{{ insight().name }}</h4>
    <p class="text-sm text-black mt-1">{{ insight().description }}</p>
  </div>

  <div [@expandSection]="expanded()">
    <div class="p-6">
      @switch (type()) {
      @case ('Budget') {
      @if (budgetInsight(); as budget) {
      <div class="space-y-6">
        @if (budget.startupCosts?.length) {
        <div class="space-y-2">
          <h4 class="font-medium">Startup Costs</h4>
          @for (cost of budget.startupCosts; track cost.item) {
          <div class="flex justify-between items-center">
            <span>{{ cost.item }}</span>
            <span>${{ cost.cost }}</span>
          </div>
          }
        </div>
        }

        @if (budget.monthlyExpenses?.length) {
        <div class="space-y-2">
          <h4 class="font-medium">Monthly Expenses</h4>
          @for (expense of budget.monthlyExpenses; track expense.item) {
          <div class="flex justify-between items-center">
            <span>{{ expense.item }}</span>
            <span>${{ expense.cost }}</span>
          </div>
          }
        </div>
        }

        @if (budget.revenueStreams?.length) {
        <div class="space-y-2">
          <h4 class="font-medium">Revenue Streams</h4>
          @for (stream of budget.revenueStreams; track stream.source) {
          <div class="flex justify-between items-center">
            <span>{{ stream.source }}</span>
            <span>${{ stream.amount }}</span>
          </div>
          }
        </div>
        }
      </div>
      }
      }

      @case ('MarketingChannels') {
      @if (marketingInsight(); as marketing) {
      <div class="space-y-4">
        @if (marketing.channels?.length) {
        <h4 class="font-medium">Marketing Channels</h4>
        @for (channel of marketing.channels; track channel.name) {
        <div class="border p-3 rounded">
          <h5 class="font-medium">{{ channel.name }}</h5>
          <p class="text-sm">{{ channel.strategy }}</p>
          <p class="text-sm text-gray-600">Budget: ${{ channel.budget }}</p>
        </div>
        }
        }

        @if (marketing.targetAudience) {
        <div class="mt-4">
          <h4 class="font-medium">Target Audience</h4>
          <p class="text-sm">{{ marketing.targetAudience }}</p>
        </div>
        }
      </div>
      }
      }


      @case ('MarketComparison') {
      @if (marketComparisonInsight(); as comparison) {
      <div class="space-y-6">
        @if (comparison.competitors?.length) {
        <div>
          <h4 class="font-medium mb-4">Competitors</h4>
          @for (competitor of comparison.competitors; track competitor.name) {
          <div class="border rounded-lg p-4 mb-4">
            <div class="flex justify-between items-center mb-3">
              <h5 class="font-medium text-lg">{{ competitor.name }}</h5>
              <span class="text-sm text-gray-600">Market Share: {{ competitor.marketShare }}</span>
            </div>
            <div class="grid md:grid-cols-2 gap-4">
              <div>
                <h6 class="font-medium text-sm mb-2">Strengths</h6>
                <ul class="list-disc pl-4 space-y-1">
                  @for (strength of competitor.strengths; track strength) {
                  <li class="text-sm">{{ strength }}</li>
                  }
                </ul>
              </div>
              <div>
                <h6 class="font-medium text-sm mb-2">Weaknesses</h6>
                <ul class="list-disc pl-4 space-y-1">
                  @for (weakness of competitor.weaknesses; track weakness) {
                  <li class="text-sm">{{ weakness }}</li>
                  }
                </ul>
              </div>
            </div>
            <div class="mt-3">
              <span class="text-sm font-medium">Pricing:</span>
              <span class="text-sm ml-2">{{ competitor.pricing }}</span>
            </div>
          </div>
          }
        </div>
        }

        @if (comparison.marketTrends?.length) {
        <div>
          <h4 class="font-medium mb-2">Market Trends</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (trend of comparison.marketTrends; track trend) {
            <li class="text-sm">{{ trend }}</li>
            }
          </ul>
        </div>
        }

        @if (comparison.competitiveAdvantages?.length) {
        <div>
          <h4 class="font-medium mb-2">Competitive Advantages</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (advantage of comparison.competitiveAdvantages; track advantage) {
            <li class="text-sm">{{ advantage }}</li>
            }
          </ul>
        </div>
        }
      </div>
      }
      }

      @case ('IdealCustomer') {
      @if (customerInsight(); as customer) {
      <div class="space-y-6">
        @if (customer.demographics) {
        <div>
          <h4 class="font-medium mb-3">Demographics</h4>
          <div class="grid md:grid-cols-2 gap-4">
            <div class="space-y-2">
              <div>
                <span class="text-sm font-medium">Age Range:</span>
                <span class="text-sm ml-2">{{ customer.demographics.ageRange }}</span>
              </div>
              <div>
                <span class="text-sm font-medium">Gender:</span>
                <span class="text-sm ml-2">{{ customer.demographics.gender }}</span>
              </div>
              <div>
                <span class="text-sm font-medium">Location:</span>
                <span class="text-sm ml-2">{{ customer.demographics.location }}</span>
              </div>
            </div>
            <div class="space-y-2">
              <div>
                <span class="text-sm font-medium">Income Level:</span>
                <span class="text-sm ml-2">{{ customer.demographics.incomeLevel }}</span>
              </div>
              <div>
                <span class="text-sm font-medium">Education:</span>
                <span class="text-sm ml-2">{{ customer.demographics.educationLevel }}</span>
              </div>
            </div>
          </div>
        </div>
        }

        @if (customer.psychographics) {
        <div>
          <h4 class="font-medium mb-3">Psychographics</h4>
          <div class="grid md:grid-cols-2 gap-6">
            @if (customer.psychographics.interests.length) {
            <div>
              <h5 class="text-sm font-medium mb-2">Interests</h5>
              <ul class="list-disc pl-4 space-y-1">
                @for (interest of customer.psychographics.interests; track interest) {
                <li class="text-sm">{{ interest }}</li>
                }
              </ul>
            </div>
            }
            @if (customer.psychographics.values.length) {
            <div>
              <h5 class="text-sm font-medium mb-2">Values</h5>
              <ul class="list-disc pl-4 space-y-1">
                @for (value of customer.psychographics.values; track value) {
                <li class="text-sm">{{ value }}</li>
                }
              </ul>
            </div>
            }
          </div>
          <div class="grid md:grid-cols-2 gap-6 mt-4">
            @if (customer.psychographics.lifestyle.length) {
            <div>
              <h5 class="text-sm font-medium mb-2">Lifestyle</h5>
              <ul class="list-disc pl-4 space-y-1">
                @for (item of customer.psychographics.lifestyle; track item) {
                <li class="text-sm">{{ item }}</li>
                }
              </ul>
            </div>
            }
            @if (customer.psychographics.buyingBehaviors.length) {
            <div>
              <h5 class="text-sm font-medium mb-2">Buying Behaviors</h5>
              <ul class="list-disc pl-4 space-y-1">
                @for (behavior of customer.psychographics.buyingBehaviors; track behavior) {
                <li class="text-sm">{{ behavior }}</li>
                }
              </ul>
            </div>
            }
          </div>
        </div>
        }

        @if (customer.painPoints?.length) {
        <div>
          <h4 class="font-medium mb-2">Pain Points</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (point of customer.painPoints; track point) {
            <li class="text-sm">{{ point }}</li>
            }
          </ul>
        </div>
        }

        @if (customer.valueProposition) {
        <div>
          <h4 class="font-medium mb-2">Value Proposition</h4>
          <p class="text-sm">{{ customer.valueProposition }}</p>
        </div>
        }

        @if (customer.marketingMessage) {
        <div>
          <h4 class="font-medium mb-2">Marketing Message</h4>
          <p class="text-sm">{{ customer.marketingMessage }}</p>
        </div>
        }
      </div>
      }
      }
      @case ('PricingStrategy') {
      @if (pricingInsight(); as pricing) {
      <div class="space-y-6">
        @if (pricing.pricingModel?.length) {
        <div>
          <h4 class="font-medium mb-2">Pricing Model</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (model of pricing.pricingModel; track model) {
            <li class="text-sm">{{ model }}</li>
            }
          </ul>
        </div>
        }

        <div class="grid md:grid-cols-2 gap-4">
          @if (pricing.costOfGoodsSold) {
          <div>
            <h4 class="font-medium mb-2">Cost of Goods Sold</h4>
            <p class="text-sm">${{ pricing.costOfGoodsSold }}</p>
          </div>
          }

          @if (pricing.desiredProfitMargin) {
          <div>
            <h4 class="font-medium mb-2">Desired Profit Margin</h4>
            <p class="text-sm">{{ pricing.desiredProfitMargin }}%</p>
          </div>
          }
        </div>

        @if (pricing.competitorPricing?.length) {
        <div>
          <h4 class="font-medium mb-2">Competitor Pricing</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (price of pricing.competitorPricing; track price) {
            <li class="text-sm">{{ price }}</li>
            }
          </ul>
        </div>
        }

        @if (pricing.valueProposition) {
        <div>
          <h4 class="font-medium mb-2">Value Proposition</h4>
          <p class="text-sm">{{ pricing.valueProposition }}</p>
        </div>
        }
      </div>
      }
      }

      @case ('ImplementationPlan') {
      @if (implementationInsight(); as implementation) {
      <div class="space-y-6">
        @if (implementation.milestones?.length) {
        <div>
          <h4 class="font-medium mb-4">Milestones</h4>
          @for (milestone of implementation.milestones; track milestone.name) {
          <div class="border rounded-lg p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <h5 class="font-medium">{{ milestone.name }}</h5>
              <span class="text-sm px-2 py-1 rounded-full" [class]="{
                'bg-green-100 text-green-800': milestone.status === 'Completed',
                'bg-yellow-100 text-yellow-800': milestone.status === 'In Progress',
                'bg-gray-100 text-gray-800': milestone.status === 'Pending'
                }">
                {{ milestone.status }}
              </span>
            </div>
            <p class="text-sm mb-2">{{ milestone.description }}</p>
            <p class="text-sm text-gray-600">
              Target Date: {{ milestone.targetDate | date:'mediumDate' }}
            </p>
          </div>
          }
        </div>
        }

        @if (implementation.resourcesNeeded?.length) {
        <div>
          <h4 class="font-medium mb-2">Required Resources</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (resource of implementation.resourcesNeeded; track resource) {
            <li class="text-sm">{{ resource }}</li>
            }
          </ul>
        </div>
        }

        @if (implementation.potentialRisks?.length) {
        <div>
          <h4 class="font-medium mb-2">Potential Risks</h4>
          <ul class="list-disc pl-4 space-y-1">
            @for (risk of implementation.potentialRisks; track risk) {
            <li class="text-sm">{{ risk }}</li>
            }
          </ul>
        </div>
        }
      </div>
      }
      }
      @default {
      <pre>{{ insight() | json }}</pre>
      }
      }
    </div>
  </div>
</div>
