// business-card.component.ts
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BusinessInsights, InsightType } from '../../types/insights';
import { InsightComponent } from '../insight/insight.component';

@Component({
  selector: 'app-business-card',
  standalone: true,
  styleUrls: ['./business-card.component.scss'],
  imports: [CommonModule, InsightComponent],
  template: `
    <div class="business-card bg-white rounded-lg shadow-lg p-6">
      <h2 class="text-xl font-bold mb-4">Business {{ business.businessId }}</h2>

      <div class="flex flex-col gap-2">
        @for (type of insightTypes; track type) {
          @if (business.insights[type]) {
            <app-insight
              [insight]="business.insights[type]!"
              [type]="type"
            />
          }
        }
      </div>
    </div>
  `
})
export class BusinessCardComponent {
  @Input({ required: true }) business!: BusinessInsights;
  readonly insightTypes: InsightType[] = ['Spark', 'Budget', 'MarketComparison', 'IdealCustomer', 'PricingStrategy', 'MarketingChannels', 'ImplementationPlan']; }
