import { Injectable } from '@angular/core';
import { Assistant } from '../types/assistant';
import { COLOR_SCHEMES, DEFAULT_COLOR } from '../types/color-scheme';
import { Message } from '../types/message';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {
  private assistants = new BehaviorSubject<Assistant[]>([]);
  assistants$ = this.assistants.asObservable();

  constructor() {
    this.loadAssistants();
  }

  async loadAssistants() {
    // TODO: replace this with API call
    this.assistants.next([
      { id: 'general', name: 'General Assistant', color: 'purple' },
      { id: 'code', name: 'Code Assistant', color: 'blue' },
      { id: 'writing', name: 'Writing Assistant', color: 'green' },
      { id: 'math', name: 'Math Assistant', color: 'orange' },
    ]);
  }

  getAssistantById(assistantId: string) {
    return this.assistants.value.find(({ id }) => id === assistantId);
  }

  getAssistantColors(assistantId: string) {
    const assistant = this.getAssistantById(assistantId);
    return COLOR_SCHEMES[assistant?.color || DEFAULT_COLOR];
  }

  async getAssistantResponse(assistantId: string, userMessage: string) {
    // TODO: replace this with API call
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(1000);

    const assistant = this.getAssistantById(assistantId);
    return assistant?.name + ' echoes: ' + userMessage;
  }

  async getAssistantHistory(assistantId: string) {
    // TODO: replace this with API call
    const history: Message[] = [
      {
        role: "bot",
        content: "Hello, how are you?",
        timestamp: new Date()
      },
      {
        role: "user",
        content: "I'm good.",
        timestamp: new Date()
      }
    ];
    return history;
  }

  async getLastUsedAssistant() {
    // TODO: replace this with API call
    const randomNumber = Math.floor(Math.random() * 4);
    return this.assistants.value[randomNumber].id;
  }
}
