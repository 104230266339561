import { Component, computed, effect, inject, signal } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';

type VerificationStatus = 'verifying' | 'success' | 'error';

@Component({
  selector: 'app-login-verify',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './verify.component.html'
})
export class VerifyComponent {
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _authService = inject(AuthService);

  // Convert query params to signals
  private readonly _queryParams = toSignal(this._route.queryParamMap);

  // Derive email and OTP from query params
  protected readonly email = computed(() =>
    this._queryParams()?.get('email') ?? ''
  );

  protected readonly otp = computed(() =>
    this._queryParams()?.get('otp') ?? ''
  );

  // Track verification status
  protected readonly verificationStatus = signal<VerificationStatus>('verifying');

  // Auth state from service
  protected readonly authState = toSignal(this._authService.authState$);

  constructor() {
    // Handle verification flow
    effect(() => {
      this.handleVerification();
    });
  }

  private async handleVerification() {
    // Early return if params aren't available yet
    if (!this._queryParams()) return;

    const currentEmail = this.email();
    const currentOtp = this.otp();

    if (!currentEmail || !currentOtp) {
      this.verificationStatus.set('error');
      return;
    }

    try {
      await this._authService.loginWithOTP(currentEmail, currentOtp);

      // Check if login was successful
      if (this.authState()?.isLoggedIn) {
        this.verificationStatus.set('success');
        // Brief delay before redirect
        setTimeout(() => {
          this._router.navigate(['/dashboard']);
        }, 1500);
      } else {
        this.verificationStatus.set('error');
      }
    } catch (error) {
      console.error('Verification failed:', error);
      this.verificationStatus.set('error');
    }
  }
}
