import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BaseComponent } from '../base.component';
import { LucideAngularModule, SquareUserIcon } from 'lucide-angular';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    LucideAngularModule,
    RouterLink
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent extends BaseComponent {
  readonly userIcon = SquareUserIcon;

  private readonly _authService = inject(AuthService);
  private readonly _router = inject(Router);

  protected readonly authState$ = this._authService.authState$;

  login() {
    this._router.navigate(['/login']);
  }

  async logout() {
    await this._authService.logout();
  }
}
