import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: 'app-base',
})
export class BaseComponent implements OnDestroy {

  private subscriptions: Subscription = new Subscription();

  subscribe<T>(observable: Observable<T>, fn: (value: T) => void) {
    this.subscriptions.add(observable.subscribe(fn));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
