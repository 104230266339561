<div class="p-6">
  @if (authState$ | async; as authState) {
    @if (authState.isLoggedIn) {
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      @for (business of organizedInsights; track business.businessId) {
        <app-business-card [business]="business" />
      }
    </div>
    }
  }
</div>
