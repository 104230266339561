import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private platformId = inject(PLATFORM_ID);
  private isBrowser = isPlatformBrowser(this.platformId);

  // Wrapper for localStorage
  getLocalStorage(key: string): string | null {
    return this.isBrowser ? localStorage.getItem(key) : null;
  }

  setLocalStorage(key: string, value: string): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  removeLocalStorage(key: string): void {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }

  // Wrapper for window
  getWindow(): (Window & typeof globalThis) | null {
    return this.isBrowser ? window : null;
  }

  // Wrapper for document
  getDocument(): Document | null {
    return this.isBrowser ? document : null;
  }

  // Helper method to run code only in browser
  runInBrowser(fn: () => void): void {
    if (this.isBrowser) {
      fn();
    }
  }
}
