import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { LucideAngularModule, SendHorizontal } from 'lucide-angular';
import { Subscription } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { MessageComponent } from './message/message.component';
import { Chat } from '../../types/chat';
import { ChatService } from '../../services/chat.service';
import { Message } from '../../types/message';
import { AssistantService } from '../../services/assistant.service';
import { Assistant } from '../../types/assistant';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LucideAngularModule,
    HeaderComponent,
    MessageComponent
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})

export class ChatComponent extends BaseComponent implements OnInit, AfterViewInit {
  // lucide icons
  readonly SendIcon = SendHorizontal

  activeChats: Chat[] = [];
  currentChat: string = 'general';
  messageInput = new FormControl('');
  assistants: Assistant[] = [];

  constructor(
    private chatService: ChatService,
    private assistantService: AssistantService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.chatService.activeChats$, chats => this.activeChats = chats)
    this.subscribe(this.chatService.currentChat$, chat => this.currentChat = chat)
    this.subscribe(this.assistantService.assistants$, assistants => this.assistants = assistants)
  }

  async ngAfterViewInit(): Promise<void> {
    await this.chatService.loadLastUsedChat();
  }

  getAssistantColors(assistantId: string): any {
    return this.assistantService.getAssistantColors(assistantId);
  }

  getCurrentChatData(): Chat | undefined {
    return this.activeChats.find(chat => chat.assistantId === this.currentChat);
  }

  addChat(assistantId: string): void {
    this.chatService.addChat(assistantId);
  }

  removeChat(assistantId: string): void {
    this.chatService.removeChat(assistantId);
  }

  setCurrentChat(assistantId: string): void {
    this.chatService.setCurrentChat(assistantId);
  }

  async sendMessage() {
    const userInput = this.messageInput.value?.trim();
    if (!userInput) return;

    const userMessage: Message = {
      role: 'user',
      content: userInput,
      timestamp: new Date()
    };
    this.chatService.addMessage(this.currentChat, userMessage);
    this.messageInput.reset();

    const assistantResponse = await this.assistantService.getAssistantResponse(this.currentChat, userInput);
    const errorResponse = "Oops! Something went wrong.";
    const assistantMessage: Message = {
      role: 'bot',
      content: assistantResponse || errorResponse,
      timestamp: new Date()
    };
    this.chatService.addMessage(this.currentChat, assistantMessage);
  }
}

