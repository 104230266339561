import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, from, map, switchMap, take } from 'rxjs';

export const sessionGuard: CanActivateFn = (_, state): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // Helper function to handle navigation based on auth state
  const handleNavigation = (isLoggedIn: boolean): boolean | UrlTree => {
    if (isLoggedIn) {
      // If logged in and trying to access landing page,
      // redirect to dashboard
      if (state.url === '/') {
        return router.parseUrl('/dashboard');
      }
      return true;
    }
    // If not logged in, redirect to login
    return router.parseUrl('/login');
  };

  return authService.authState$.pipe(
    take(1),
    switchMap(authState => {
      // Only make the API call if we're not already logged in
      if (!authState.isLoggedIn) {
        // Convert the Promise to Observable and handle the result
        return from(authService.loginWithToken()).pipe(
          switchMap(success => {
            if (success) {
              // If login successful, get the latest auth state
              return authService.authState$.pipe(take(1));
            }
            // If login failed, return current state
            return from([authState]);
          })
        );
      }
      return from([authState]);
    }),
    map(authState => handleNavigation(authState.isLoggedIn))
  );
};
