export interface ColorScheme {
  bg: string;
  hover: string;
  light: string;
  text: string;
}

export type Color = 'blue' | 'green' | 'orange' | 'purple';
export const DEFAULT_COLOR: Color = 'blue';

export const COLOR_SCHEMES: Record<Color, ColorScheme> = {
  blue: { bg: 'bg-blue-600', hover: 'hover:bg-blue-700', light: 'bg-blue-100', text: 'text-blue-600' },
  green: { bg: 'bg-emerald-600', hover: 'hover:bg-emerald-700', light: 'bg-emerald-100', text: 'text-emerald-600' },
  orange: { bg: 'bg-orange-600', hover: 'hover:bg-orange-700', light: 'bg-orange-100', text: 'text-orange-600' },
  purple: { bg: 'bg-purple-600', hover: 'hover:bg-purple-700', light: 'bg-purple-100', text: 'text-purple-600' },
}
