import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { BusinessInsights, BaseInsight } from '../../types/insights';
import { BusinessCardComponent } from '../business-card/business-card.component';
import { InsightsService } from '../../services/insights.service';
import { AuthService } from '../../services/auth.service';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-insights-dashboard',
  standalone: true,
  imports: [CommonModule, BusinessCardComponent],
  templateUrl: './insights-dashboard.component.html',
  styleUrls: ['./insights-dashboard.component.scss']
})
export class InsightsDashboardComponent implements OnInit {
  private readonly _insightsService = inject(InsightsService);
  private readonly _authService = inject(AuthService);

  protected insights: BaseInsight[] = [];
  protected organizedInsights: BusinessInsights[] = [];

  // Only access auth state when needed
  protected readonly authState$ = this._authService.authState$;

  ngOnInit(): void {
    this._loadInsights();
  }

  private _loadInsights(): void {
    // Wait for authenticated state before loading insights
    this._authService.authState$.pipe(
      filter(state => state.isLoggedIn && !!state.email),
      switchMap(state => this._insightsService.getUserInsights(state.email))
    ).subscribe(insights => {
      this.insights = insights;
      this._organizeInsights();
    });
  }

  private _organizeInsights(): void {
    const businessMap = new Map<string, BusinessInsights>();

    this.insights.forEach(insight => {
      const businessId = insight.businessId.$oid;

      if (!businessMap.has(businessId)) {
        businessMap.set(businessId, {
          businessId,
          insights: {}
        });
      }

      const business = businessMap.get(businessId)!;
      business.insights[insight.__t] = insight;
    });

    this.organizedInsights = Array.from(businessMap.values());
  }
}
