import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-email-sent',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './sent.component.html',
  styleUrl: './sent.component.scss'
})
export class EmailSentComponent extends BaseComponent implements OnInit {

  email: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super()
  }

  ngOnInit() {
    this.subscribe(this.route.queryParamMap, (params) => {
      const email = params.get('email');
      if(email) this.email = params.get('email') || '';
      else this.router.navigate(['/']);
    });
  }
}
