import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Chat } from '../types/chat';
import { Message } from '../types/message';
import { AssistantService } from './assistant.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private activeChats = new BehaviorSubject<Chat[]>([]);
  private currentChat = new BehaviorSubject<string>('general');
  activeChats$ = this.activeChats.asObservable();
  currentChat$ = this.currentChat.asObservable();

  constructor(private assistantService: AssistantService) { }

  async addChat(assistantId: string): Promise<void> {
    const assistant = this.assistantService.getAssistantById(assistantId);
    const chatHistory = await this.assistantService.getAssistantHistory(assistantId);
    const messages: Message[] = chatHistory.length > 0
      ? chatHistory
      : [{
        role: 'bot',
        content: `Hello! I'm the ${assistant?.name}. How can I help?`,
        timestamp: new Date()
      }];

    const newChat: Chat = { assistantId, messages };
    this.activeChats.next([...this.activeChats.value, newChat]);
    this.currentChat.next(assistantId);
  }

  removeChat(assistantId: string): void {
    const updatedChats = this.activeChats.value.filter(chat => chat.assistantId !== assistantId);
    this.activeChats.next(updatedChats);

    if (this.currentChat.value === assistantId) {
      this.currentChat.next(updatedChats[0]?.assistantId);
    }
  }

  setCurrentChat(assistantId: string): void {
    this.currentChat.next(assistantId);
  }

  addMessage(assistantId: string, message: Message): void {
    const updatedChats = this.activeChats.value.map(chat =>
      chat.assistantId === assistantId
        ? { ...chat, messages: [...chat.messages, message] }
        : chat
    );
    this.activeChats.next(updatedChats);
  }

  async loadLastUsedChat() {
    if (this.activeChats.value.length > 0) return;
    const assistantId = await this.assistantService.getLastUsedAssistant();
    await this.addChat(assistantId);
  }
}
