<div class="flex flex-col items-center justify-center min-h-screen bg-gray-50">
  <h2 class="text-2xl font-semibold text-gray-800 mb-4">Check Your Email</h2>
  <p class="text-gray-700">
    We've sent a login link to {{ email }}.
  </p>
  <button
    routerLink="/"
    class="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none"
  >
    Return Home
  </button>
</div>
