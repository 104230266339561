<div
  [class]="
    'flex items-start gap-3 ' +
    (message.role === 'user' ? 'flex-row-reverse' : '')
  "
>
  <div
    [class]="
      'flex-shrink-0 rounded-full p-2 ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistantId).light
        : 'bg-gray-100')
    "
  >
    <lucide-icon
      [img]="message.role === 'user' ? UserIcon : BotIcon"
      [size]="20"
      [class]="
        message.role === 'user'
          ? getAssistantColors(currentAssistantId).text
          : 'text-gray-600'
      "
    ></lucide-icon>
  </div>

  <div
    [class]="
      'px-4 py-2 rounded-2xl max-w-[80%] ' +
      (message.role === 'user'
        ? getAssistantColors(currentAssistantId).bg + ' text-white'
        : 'bg-white border border-gray-200')
    "
  >
    <p class="text-sm">{{ message.content }}</p>
    <time class="text-xs text-gray-400 mt-1">
      {{ message.timestamp | date : "shortTime" }}
    </time>
  </div>
</div>
