<div class="flex flex-col items-center justify-center min-h-full p-4">
  @if (verificationStatus() === 'verifying') {
    <div class="flex flex-col items-center text-center">
      <div class="flex items-center justify-center">
        <svg
          class="h-10 w-10 text-primary-600 mb-4 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </div>
      <h2 class="text-2xl font-semibold text-grey-800">Verifying...</h2>
    </div>
  } @else if (verificationStatus() === 'success') {
    <div class="flex flex-col items-center text-center">
      <div class="flex items-center justify-center">
        <svg
          class="h-10 w-10 text-green-500 mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <h2 class="text-2xl font-semibold text-grey-800 mb-2">
        Verification Successful!
      </h2>
      <button
        routerLink="/"
        class="mt-6 bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700 focus:outline-none"
      >
        Proceed
      </button>
    </div>
  } @else {
    <div class="flex flex-col items-center text-center">
      <div class="flex items-center justify-center">
        <svg
          class="h-10 w-10 text-danger-500 mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <h2 class="text-2xl font-semibold text-grey-800 mb-2">
        Verification Failed
      </h2>
      <p class="text-grey-700">
        The OTP is invalid or has expired. Please request a new login link.
      </p>
      <button
        routerLink="/login"
        class="mt-6 bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700 focus:outline-none"
      >
        Back to Login
      </button>
    </div>
  }
</div>
