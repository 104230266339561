import { Routes } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { EmailSentComponent } from './components/login/sent/sent.component';
import { VerifyComponent } from './components/login/verify/verify.component';
import { InsightsDashboardComponent } from './components/insights-dashboard/insights-dashboard.component';
import { sessionGuard } from './guards/session.guard';

export const routes: Routes = [
  {
    path: 'assistant',
    component: ChatComponent,
    canActivate: [sessionGuard],
    title: 'Chat Assistant'
  },
  {
    path: 'login',
    children: [
      {
        path: 'verify',
        component: VerifyComponent,
        title: 'Verify Login'
      },
      {
        path: 'sent',
        component: EmailSentComponent,
        title: 'Email Sent'
      },
      {
        path: '',
        component: LoginComponent,
        title: 'Login'
      }
    ]
  },
  {
    path: 'dashboard',
    component: InsightsDashboardComponent,
    canActivate: [sessionGuard],
    title: 'Dashboard'
  },
  {
    path: '',
    component: LandingComponent,
    canActivate: [sessionGuard],
    title: 'Welcome'
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
