import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  private readonly _API_URL = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient
  ) { }

  async getUserInsights(email: string): Promise<any> {
    const response = await firstValueFrom(
      this._httpClient.get(`${this._API_URL}/api/v1/insights/${email}`, { withCredentials: true })
        .pipe(
          catchError(error => {
            console.error('Error getting user insights:', error);
            return throwError(() => error);
          })
        )
    );
    return response;
  }

}
