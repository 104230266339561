import { CommonModule } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import {
  BaseInsight,
  InsightType,
  BudgetInsight,
  MarketingChannelsInsight,
  MarketComparisonInsight,
  PricingStrategyInsight,
  ImplementationPlanInsight,
  IdealCustomerInsight
} from '../../types/insights';
import { insightAnimations } from './insight.animations';
import { FormsModule } from '@angular/forms';
import { Info, LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-insight',
  standalone: true,
  imports: [CommonModule, FormsModule, LucideAngularModule],
  templateUrl: './insight.component.html',
  animations: [
    insightAnimations.expandSection,
    insightAnimations.staggerItems
  ]
})
export class InsightComponent {
  insight = input.required<BaseInsight>();
  type = input.required<InsightType>();
  expanded = signal(false);

  infoIcon = Info;

  // Color computations
  protected readonly borderColorClass = computed(() => {
    const colorMap: Record<InsightType, string> = {
      'Spark': 'border-primary-500',
      'Budget': 'border-danger-500',
      'MarketComparison': 'border-success-500',
      'PricingStrategy': 'border-warning-500',
      'MarketingChannels': 'border-indigo-500',
      'IdealCustomer': 'border-purple-500',
      'ImplementationPlan': 'border-pink-500'
    };
    return colorMap[this.type()];
  });

  protected readonly textColorClass = computed(() => {
    const colorMap: Record<InsightType, string> = {
      'Spark': 'text-primary-700',
      'Budget': 'text-danger-700',
      'MarketComparison': 'text-success-700',
      'PricingStrategy': 'text-warning-700',
      'MarketingChannels': 'text-indigo-700',
      'IdealCustomer': 'text-purple-700',
      'ImplementationPlan': 'text-pink-700'
    };
    return colorMap[this.type()];
  });

  // Type-specific computed properties
  protected readonly budgetInsight = computed(() =>
    this.type() === 'Budget' ? this.insight() as BudgetInsight : null
  );

  protected readonly marketingInsight = computed(() =>
    this.type() === 'MarketingChannels' ? this.insight() as MarketingChannelsInsight : null
  );

  protected readonly marketComparisonInsight = computed(() =>
    this.type() === 'MarketComparison' ? this.insight() as MarketComparisonInsight : null
  );

  protected readonly pricingInsight = computed(() =>
    this.type() === 'PricingStrategy' ? this.insight() as PricingStrategyInsight : null
  );

  protected readonly implementationInsight = computed(() =>
    this.type() === 'ImplementationPlan' ? this.insight() as ImplementationPlanInsight : null
  );

  protected readonly customerInsight = computed(() =>
    this.type() === 'IdealCustomer' ? this.insight() as IdealCustomerInsight : null
  );

  handleClick() {
    this.expanded.update(state => !state);
  }
}
