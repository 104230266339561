import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LucideAngularModule, User, Bot } from 'lucide-angular';
import { Message } from '../../../types/message';
import { AssistantService } from '../../../services/assistant.service';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, DatePipe],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent {
  // lucide icons
  readonly UserIcon = User;
  readonly BotIcon = Bot;

  @Input() message!: Message;
  @Input() currentAssistantId!: string;

  constructor(private assistantService: AssistantService) { }

  getAssistantColors(assistantId: string) {
    return this.assistantService.getAssistantColors(assistantId);
  }
}
