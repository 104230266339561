<button
  routerLink="destination"
  class="flex items-center text-gray-700 hover:text-gray-900 focus:outline-none"
  aria-label="Go back to landing page"
>
  <!-- Chevron Left Icon -->
  <svg
    class="h-6 w-6 mr-2"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
  </svg>
</button>
