import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-back-button',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './back.component.html',
  styleUrl: './back.component.scss'
})
export class BackButtonComponent {
  @Input() destination: string = '/'; // Default destination is the landing page

  constructor(private router: Router) {}

  navigateBack(): void {
    this.router.navigate([this.destination]);
  }
}

